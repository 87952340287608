import { Component, Vue } from 'vue-property-decorator'

import PublicNavbarLeis from '@/components/publicNavbarLeis/PublicNavbarLeis.vue'
import FiltroLeis from '@/components/filtroLeis/FiltroLeis.vue'
import ListaLeis from '@/components/listaLeis/ListaLeis.vue'

import { api } from '@/services/api'

@Component({

	components:{
		PublicNavbarLeis,
    ListaLeis,
    FiltroLeis
	}

})

export default class PublicHome extends Vue {

  leis = [];
  temas = [];
  countLeis = 0
  loading = false;
  filtro: any = {
    numero: '',
    ano: '',
    situacao: '',
    tipo: '',
    tema: ''
  };
  pagination: any = {
    _page: 1,
    _limit: 10,
    _sort: 'id',
    _order: 'desc'
  };
  bpagination = 1;

  mounted() {
    this.sendGet(this.mountUrlQueryPagination());
    this.sendGetAllTemas();
  }

  changePagination(param: any){
    console.log(param);
    this.pagination._page = param;
    this.clickBtnProcurar();
  }

  clickBtnProcurar(){
    const query = this.mountUrlQuery();
    console.log(query);
    this.sendGet(query);
  }

  mountUrlQuery(){
    let query = this.mountUrlQueryPagination();
    query = query + '&' + this.mountUrlQueryFiltro();
    return query;
  }

  mountUrlQueryPagination(){
    let str  = '';
    let i = 1;
    for(const key in this.pagination){
      str = str + key + '=' + this.pagination[key];
      if( i >= 2 && i < Object.keys(this.pagination).length){
        str = str + '&'
      }
      i = i + 1;
    }
    return str;
  }

  mountUrlQueryFiltro(){
    let str  = '';
    let i = 1;
    for(const key in this.filtro){
      console.log(key, this.filtro[key]);
      if( this.filtro[key] != '' ){
        if(key == 'ementa'){
          str = str + 'ementa_like' + '=' + this.filtro[key];
        }
        else{
          str = str + key + '=' + this.filtro[key];
        }
        // adiciona caracter & na query
        if( i >= 1 && i < Object.keys(this.filtro).length){
          str = str + '&'
        }
        console.log(str);
        
      }
      i = i + 1;
    }
    return str;
  }

  sendGet(query: string){
    this.loading = true;
    api.get('/leis?'+query)
      .then( (response) => {
        this.countLeis = response.headers["x-total-count"];
        this.leis = response.data;
        this.loading = false;
        console.log(response);
      })
      .catch( (error) => {
        this.loading = false;
        console.log(error);
      })
  }

  sendGetAllTemas(){
    // this.loading = true;
    api.get('/temas')
      .then( (response) => {
        // this.countLeis = response.headers["x-total-count"];
        this.temas = response.data;
        // this.loading = false;
        console.log(response);
      })
      .catch( (error) => {
        // this.loading = false;
        console.log(error);
      })
  }

}
