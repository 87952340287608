import { Component, Prop, Vue } from 'vue-property-decorator'

// import { api } from '@/services/api'

@Component({
	
})
export default class ListaLeis extends Vue {
  @Prop() list!: Array<object>;
  
  exibir = 'is-12';
  mark = true;
  
  changeExibir(){
    this.mark = !this.mark;
    console.log(this.mark);
    if(this.mark){
      this.exibir = 'is-12';
    }
    else{
      this.exibir = 'is-6';
    }
  }
} 
