import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
	
})
export default class FiltroLeis extends Vue {
  @Prop() isLoading!: boolean;

  loading = false;
  

  mounted() {
    this.loading = this.isLoading
  }
}
